import Vue from "vue";

Vue.mixin({
    methods: {
        capitalizeFirstLetter(str) {
            return str.toUpperCase();
        },
        HumanReadableDateFormat(date) {
            const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
            ];
            let dt = new Date(date);
            let day = ("0" + dt.getDate()).slice(-2);
            let month = dt.getMonth();
            let year = dt.getFullYear();
            let hour = ("0" + dt.getHours()).slice(-2);
            let minute = ("0" + dt.getMinutes()).slice(-2);
            let seconds = ("0" + dt.getSeconds()).slice(-2);
            return day + " " + monthNames[month] + " " + year + " " + hour + ":" + minute + ":" + seconds;
        },
        ReplaceString(str, split,join) {
            if (str) {
                return str.split(split).join(join);
            } else {
                return str;
            }
        },
        WhiteLabellingTitle() {
            return "STEER SM";
        },
        GetServerBaseUrl() {
            return process.env.VUE_APP_API_BASE_URL
        }
    }
})