<template>
    <div>
        <div style="float: right">
          <md-button class="md-success" @click="$router.push({ path: 'create-customer'})">Create Customer</md-button>
        </div>
        <md-card>
            <md-card-content>
                <div class="md-layout">
                    <div class="md-layout-item md-size-100">
                        <md-table v-if="tableData.length > 0 " 
                          v-model="tableData" 
                          class="md-container-custom paginated-table" table-header-color="green">
                            <md-table-row slot="md-table-row" slot-scope="{ item }">            
                                <md-table-cell md-label="Serial Number"> {{ item.name }} </md-table-cell>
                                <md-table-cell md-label="Email"> {{ item.email }} </md-table-cell>
                                <md-table-cell md-label="Login Key">
                                   <p :style="{ 'line-break':'anywhere' }">{{ item.loginApiKey }} </p>
                                </md-table-cell>
                                <md-table-cell md-label="Created On" md-sort-by="created_at">{{ HumanReadableDateFormat(item.created_at) }}</md-table-cell>
                            </md-table-row>
                        </md-table>
                        <h4 v-else>No data found.</h4>
                    </div>
                </div>
            </md-card-content>
        </md-card>
    </div>
</template>
<script>
import CustomerService from "../../services/CustomerService";

export default {
  components: {
  },
  data() {
    return {
      isDisabled: false,
      tableData: [],

      //Sorting
      currentSort: "created_at",
      currentSortOrder: "asc",
    };
  },
  methods: {
      listCustomers() {
        this.isDisabled = true;
        let data = { appName: this.appName }
        CustomerService.listCustomers(data)
          .then((response) => {
            this.isDisabled = false;
            this.tableData = response.data.data;
            //this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.respones_msg);
          });
      },
  },
  beforeMount() {
    this.listCustomers();
  },
};
</script>
<style>
.md-table[table-header-color=green] .md-table-head .md-table-head-label {
    color: #376092 !important;
}
</style>