<template>
    <div>
        <div style="float: right">
            <md-button class="md-success" :disabled="isDisabled" @click="createCustomer()">Save<span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span></md-button>
        </div>
        <md-card>
            <md-card-content>
                <form ref="webinarForm">
                    <div class="md-layout">
                        <div class="md-layout-item md-size-50">
                            <md-field>
                                <label>Name</label>
                                <md-input type="text" v-model="name"></md-input>
                            </md-field>
                        </div>
                        <div class="md-layout-item md-size-50">
                            <md-field>
                                <label>Company Name</label>
                                <md-input type="text" v-model="companyName"></md-input>
                            </md-field>
                        </div>
                    </div>
                    <div class="md-layout">
                        <div class="md-layout-item md-size-50">
                            <md-field>
                                <label>Email</label>
                                <md-input type="text" v-model="email"></md-input>
                            </md-field>
                        </div>
                        <div class="md-layout-item md-size-50">
                            <md-field>
                                <label>Password</label>
                                <md-input type="password" v-model="password"></md-input>
                            </md-field>
                        </div>
                    </div>
                    <div class="md-layout">
                        <div class="md-layout-item md-size-33">
                            <md-field>
                                <label>API Key</label>
                                <md-input type="text" v-model="apiKey"></md-input>
                            </md-field>
                        </div>
                        <div class="md-layout-item md-size-33">
                            <md-field>
                                <label>API Username</label>
                                <md-input type="text" v-model="apiUsername"></md-input>
                            </md-field>
                        </div>
                        <div class="md-layout-item md-size-33">
                            <md-field>
                                <label>API Password</label>
                                <md-input type="text" v-model="apiPassword"></md-input>
                            </md-field>
                        </div>
                    </div>
                </form>
            </md-card-content>
        </md-card>
    </div>
</template>
<script>
import CustomerService from "../../services/CustomerService";
export default {
    components: {
    },
    data() {
        return {
            isDisabled: false,
            name: "",
            email: "",
            companyName:"",
            password:"",
            apiKey:"",
            apiUsername:"",
            apiPassword:"",
        }
    },
    methods: {
        createCustomer() {
            this.isDisabled =true;
            let data = { name: this.name, email: this.email, companyName: this.companyName, password: this.password,
             apiKey: this.apiKey, apiUsername: this.apiUsername, apiPassword: this.apiPassword }
            CustomerService.create(data)
            .then((response) => {
                this.isDisabled =false;
                //this.$toast.success(response.data.respones_msg);
                this.$router.push("/customers");
            })
            .catch((e) => {
                this.isDisabled =false;
                this.$toast.error(e.response.data.respones_msg);
            });
        }
    }
}
</script>