<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100 md-medium-size-100">
      <md-card>
        <!--<md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>password</md-icon>
          </div>
          <h4 class="title">Change Password</h4>
        </md-card-header>-->

        <md-card-content>
          <md-field>
            <label>Old Password</label>
            <md-input v-model="old_password" type="password"></md-input>
          </md-field>
          <md-field>
            <label>New Password</label>
            <md-input v-model="new_password" type="password"></md-input>
          </md-field>
          <md-field>
            <label>Confirm Password</label>
            <md-input v-model="confirm_password" type="password"></md-input>
          </md-field>
        </md-card-content>

        <md-card-actions md-alignment="left">
          <md-button class="md-success" :disabled="isDisabled" @click="ChangePassword()">Submit</md-button>
        </md-card-actions>
      </md-card>  
    </div>
  </div>
</template>
<script>
import UsersDataService from "../../services/UsersDataService";

export default {
  data() {
    return {
      isDisabled: false,
      old_password: null,
      new_password: null,
      confirm_password: null,
    }
  },
  methods: {
    ChangePassword() {
      this.isDisabled = true;
      var data = {
        old_password: this.old_password,
        new_password: this.new_password,
        confirm_password: this.confirm_password,
      };

      UsersDataService.ChangePassword(data)
        .then((response) => {
          this.isDisabled = false;
          localStorage.setItem('token', "");
          UsersDataService.Logout()
          .then((response) => {})
          .catch((e) => {
          });
          this.$router.push('/login');
          //this.$toast.success(response.data.respones_msg);
        })
        .catch((e) => {
          this.isDisabled = false;
          this.$toast.error(e.response.data.respones_msg);
        });
    },
  },
};
</script>