<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100 md-medium-size-100">
      <md-card>
        <!--<md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>groups</md-icon>
          </div>
          <h4 class="title">API Credentials</h4>
        </md-card-header>-->

        <md-card-content>
          <p :style="{ 'line-break':'anywhere' }"><b>API Key :</b> {{ this.userDetail.loginApiKey }}</p>
          <div>
            <md-button class="md-success" :disabled="isDisabled" @click="reGenerateLoginApiKey()">ReGenerate<span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span></md-button>
          </div>
        </md-card-content>
      </md-card>  
    </div>
  </div>
</template>
<script>
import UsersDataService from "../../services/UsersDataService";
import CustomerService from "../../services/CustomerService";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      isDisabled: false,
      userDetail: null,
    }
  },
  methods: {
    GetAdminDetail() {
      var data = {};
      UsersDataService.getUserDetail(data)
        .then((response) => {
          this.userDetail = response.data.data;
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    reGenerateLoginApiKey() {
      Swal.fire({
          title: "Are you sure?",
          text: `You won't be able to revert this! Your old api key will not work once new key is generated`,
          type: "warning",
          showCancelButton: true,
          confirmButtonClass: "md-button md-success",
          cancelButtonClass: "md-button md-danger",
          confirmButtonText: "Yes, Regenerate!",
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            CustomerService.reGenerateLoginApiKey()
            .then((response) => {
              this.GetAdminDetail();
              //this.$toast.success(response.data.responseMsg);
            })
            .catch((e) => {
              this.$toast.error(e.response.data.respones_msg);
            }); 
          }
        });
    },
  },
  beforeMount(){
    this.GetAdminDetail()
  },
};
</script>
<style scoped>
.md-button, .md-button:hover, .md-button:focus, .md-button:active, .md-button.active, .md-button:active:focus, .md-button:active:hover, .md-button.active:focus, .md-button.active:hover, .open > .md-button.dropdown-toggle, .open > .md-button.dropdown-toggle:focus, .open > .md-button.dropdown-toggle:hover, .md-button.md-default, .md-button.md-default:hover, .md-button.md-default:focus, .md-button.md-default:active, .md-button.md-default.active, .md-button.md-default:active:focus, .md-button.md-default:active:hover, .md-button.md-default.active:focus, .md-button.md-default.active:hover, .open > .md-button.md-default.dropdown-toggle, .open > .md-button.md-default.dropdown-toggle:focus, .open > .md-button.md-default.dropdown-toggle:hover{
  background-color: #376092 !important
}
</style>