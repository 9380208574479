import http from "../http-common";

class SecurityObjectService {
    list() {
      return http.get("security/list/keys");
    }
    deleteKey(data) {
      return http.delete("security/delete/key", {params:data});
    }
    create(data) {
      return http.post("security/generate/key", data);
    }
    keyAttestation(data) {
      return http.post("security/attestation/key", data);
    }
    generateJWS(data) {
      return http.post("security/JWSsigning", data);
    }
}

export default new SecurityObjectService();