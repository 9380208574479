<template>
  <div>
    <div class="md-layout" v-if="userRole != 'Admin'">
      <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-33">
          <stats-card header-color="blue">
            <template slot="header">
              <div class="card-icon">
                <md-icon>key</md-icon>
              </div>
              <p class="category">Total AES Key</p>
              <h3 class="title">
                <a href="javascript:void(0)" :style="{ color: '#376092 !important' }" ><span>{{ aesKeysCount }}</span></a>
              </h3>
            </template>
          </stats-card>
        </div>
        <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-33">
          <stats-card header-color="blue">
            <template slot="header">
              <div class="card-icon">
                <md-icon>key</md-icon>
              </div>
              <p class="category">Total RSA Key</p>
              <h3 class="title">
                <a href="javascript:void(0)" :style="{ color: '#376092 !important' }"><span>{{ rsaKeysCount }}</span></a>
              </h3>
            </template>
          </stats-card>
        </div>
        <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-33">
          <stats-card header-color="blue">
            <template slot="header">
              <div class="card-icon">
                <md-icon>key</md-icon>
              </div>
              <p class="category">Total EC Key</p>
              <h3 class="title">
                <a href="javascript:void(0)" :style="{ color: '#376092 !important' }"><span>{{ ecKeysCount }}</span></a>
              </h3>
            </template>
          </stats-card>
        </div>
    </div>
    <div class="md-layout" v-if="userRole == 'Admin'">
      <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-33">
          <stats-card header-color="blue">
            <template slot="header">
              <div class="card-icon">
                <md-icon>group</md-icon>
              </div>
              <p class="category">Total Customers</p>
              <h3 class="title">
                <a href="javascript:void(0)" :style="{ color: 'blue !important' }" ><span>{{ totalCustomers }}</span></a>
              </h3>
            </template>
          </stats-card>
        </div>
        <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-33">
          <stats-card header-color="blue">
            <template slot="header">
              <div class="card-icon">
                <md-icon>key</md-icon>
              </div>
              <p class="category">Total API Call</p>
              <h3 class="title">
                <a href="javascript:void(0)" :style="{ color: 'blue !important' }"><span>{{ totalApiCallCounts }}</span></a>
              </h3>
            </template>
          </stats-card>
        </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item md-size-50 md-small-size-100">
        <chart-card
          header-animation="false"
          :chart-data="simpleBarChart.data"
          :chart-options="simpleBarChart.options"
          :chart-responsive-options="simpleBarChart.responsiveOptions"
          chart-type="Bar"
          header-icon
          chart-inside-content
          no-footer
          background-color="green"
        >
        <template slot="chartInsideHeader">
            <div class="card-icon">
              <md-icon>insert_chart</md-icon>
            </div>
            <h4 :style="{'color': 'white'}">
              API Call History
            </h4>
          </template>
        </chart-card>
      </div>
    </div>
  </div>
</template>

<script>
import CustomerService from "../../services/CustomerService";
import {
  StatsCard,
  ChartCard,
} from "@/components";
export default {
  components: {
    StatsCard,
    ChartCard,
  },
  data() {
    return {
      rsaKeysCount: 0,
      aesKeysCount: 0,
      ecKeysCount: 0,
      totalApiCallCounts: 0,
      totalCustomers: 0,
      userRole: "",
      simpleBarChart: null,
    };
  },
  methods: {
  },
  mounted() {
  },
  async created() {
    await CustomerService.getDashboardData()
      .then((response) => {
        this.rsaKeysCount = response.data.data.rsaKeysCount;
        this.aesKeysCount = response.data.data.aesKeysCount;
        this.ecKeysCount = response.data.data.ecKeysCount;
        this.totalApiCallCounts = response.data.data.totalApiCallCounts;
        this.totalCustomers = response.data.data.totalCustomers;
        this.userRole = response.data.data.userRole;

        this.simpleBarChart = {
          data: {
            labels: ["Jan", "Feb", "March", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"],
            series: [
              response.data.data.monthWiseApiCallCount,
            ],
          },
          options: {
            height: "230px",
            seriesBarDistance: 10,
            axisX: {
              showGrid: false,
            },
          },
          responsiveOptions: [
            [
              "screen and (max-width: 640px)",
              {
                seriesBarDistance: 5,
                axisX: {
                  labelInterpolationFnc: function (value) {
                    return value[0];
                  },
                },
              },
            ],
          ],
        }
        //this.$toast.success(response.data.responseMsg);
      })
      .catch((e) => {
        this.isDisabled = false;
        this.$toast.error(e.response.data.respones_msg);
      });
  }
};
</script>