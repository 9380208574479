<template>
  <div class="md-layout text-center">
    <div
      class="md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100"
    >
    <div class="alert alert-success alert-dismissible fade show" role="alert">
      <strong>OTP!</strong> Check your email for otp.
    </div><br>
      <login-card header-color="green">
        <h4 slot="title" class="title">Verify OTP</h4>
        <md-field class="md-form-group" slot="inputs">
          <md-icon>email</md-icon>
          <label>Email</label>
          <md-input v-model="email" type="email" disabled></md-input>
        </md-field>
        <md-field class="md-form-group" slot="inputs">
          <md-icon>lock_outline</md-icon>
          <label>OTP</label>
          <md-input v-model="otp" type="number" maxlength="4"></md-input>
        </md-field>
        <div slot="footer">
          <md-button class="md-success" v-on:click="VerifyOtp()" :disabled="isDisabled">
            Verify OTP
          </md-button>  
          <hr>
          <a class="fs-12" href="javascript:void(0)" @click="$router.push('/super-admin/login')">Login</a>
        </div>
      </login-card>
    </div>
  </div>
</template>
<script>
import { LoginCard } from "@/components";
import UserDataService from "../../../services/UsersDataService";
export default {
  components: {
    LoginCard,
  },
  data() {
    return {
      otp: null,
      email: this.$route.query.email,
      isDisabled: false,
    };
  },
  methods: {
    VerifyOtp() {
        this.isDisabled = true;
        var data = {
            otp: this.otp,
            email: this.email
        };
        UserDataService.VerifyOtp(data)
            .then((response) => {
                this.isDisabled = false;
                this.$toast.success(response.data.respones_msg);
                localStorage.setItem('token', response.data.data.token);
                document.location = "/dashboard";
            })
            .catch((e) => {
                this.isDisabled = false;
                this.$toast.error(e.response.data.respones_msg);
            });
    },
  },
  beforeMount() {
    
  },
};
</script>

<style>
.fs-12{
  font-size: 12px;
}
</style>
