import http from "../http-common";

class CustomerService {
    listCustomers() {
      return http.get("customer/list");
    }
    create(data) {
      return http.post("customer/create", data);
    }
    getDashboardData() {
      return http.get("customer/dashboard");
    }
    reGenerateLoginApiKey() {
      return http.get("customer/regenerate/loginApiKey");
    }
}

export default new CustomerService();