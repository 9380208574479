<template>
    <div>
        <div style="float: right">
          <md-button class="md-success" @click="$router.push({ path: '/sobjects/create'})">Create Security Object</md-button>
        </div>
        <md-card>
            <md-card-content>
                <div class="md-layout">
                    <div class="md-layout-item md-size-100">
                        <md-table v-if="tableData.length > 0 " 
                          v-model="tableData" 
                          class="md-container-custom paginated-table" table-header-color="green">
                            <md-table-row slot="md-table-row" slot-scope="{ item }">            
                                <md-table-cell md-label="kid"> {{ item.kid }} </md-table-cell>
                                <md-table-cell md-label="Name"> {{ item.name }} </md-table-cell>
                                <md-table-cell md-label="Type"> {{ item.obj_type }} </md-table-cell>
                                <md-table-cell md-label="Actions">
                                  <md-button class="md-just-icon md-info md-simple" :style="{ display: 'none' }" @click="openDeleteKeyModal(item)"><md-icon>token</md-icon></md-button>
                                  <md-button class="md-just-icon md-info md-simple" @click="openJWSModal(item)"><md-icon title="Generate JWS">token</md-icon></md-button>
                                  <md-button class="md-just-icon md-danger md-simple" @click="openDeleteKeyModal(item)"><md-icon>close</md-icon></md-button>
                                </md-table-cell>
                            </md-table-row>
                        </md-table>
                        <h4 v-else>No data found.</h4>
                    </div>
                </div>
            </md-card-content>
        </md-card>
        <!-- Delete Key Modal -->
      <template>
          <modal v-if="deleteKeyModal" @close="deleteKeyModal = false">
            <template slot="header">
              <h4 class="modal-title">Are you sure you want to delete : <b>{{ deleteKeyName }}</b></h4>
              <md-button
                style="height: 25px" class="md-simple md-just-icon md-round modal-default-button"
                @click="deleteKeyModal = false"><md-icon>clear</md-icon></md-button>
            </template>
            <template slot="body">
            <div class="box-body">
              <div class="alert alert-danger" role="alert">
                 Warning: this cannot be undone.
              </div>
              <div class="md-layout">
                I understand that,
                <div class="md-layout-item md-size-100">
                  <md-checkbox v-model="deletedCheckBox1">The object cannot be restored once it is deleted</md-checkbox>
                  <md-checkbox v-model="deletedCheckBox2">Data encrypted with the object can no longer be used once the object is deleted</md-checkbox>
                </div>
              </div>
            </div>
            </template>
            <template slot="footer">
              <md-button class="md-danger md-simple" @click="deleteKeyModal = false">Close</md-button>
              <md-button class="md-success" :disabled="!deletedCheckBox1 || !deletedCheckBox2" @click="deleteKey()">Proceed</md-button>
            </template>
          </modal>
      </template>

      <!-- Generate JWS Token Modal -->
      <template>
          <modal v-if="jwsModal" @close="jwsModal = false">
            <template slot="header">
              <h4 class="modal-title">Generate JWS For : <b>{{ deleteKeyName }}</b></h4>
              <md-button
                style="height: 25px" class="md-simple md-just-icon md-round modal-default-button"
                @click="jwsModal = false"><md-icon>clear</md-icon></md-button>
            </template>
            <template slot="body">
            <div class="box-body">
              <div class="md-layout">
                <div class="md-layout-item md-size-100" v-if="!this.jwsToken">
                  <md-field>
                      <label>Payload</label>
                      <md-input type="text" v-model="payload"></md-input>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-100" v-if="this.jwsToken">
                  <p :style="{ 'line-break':'anywhere' }"><b>Token :</b> {{ this.jwsToken }}</p>
                </div>
              </div>
            </div>
            </template>
            <template slot="footer">
              <md-button class="md-danger md-simple" @click="jwsModal = false">Close</md-button>
              <md-button class="md-success"  @click="generateJWS()" :disabled="isDisabled">Generate JWS<span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span></md-button>
            </template>
          </modal>
      </template>
    </div>
</template>
<script>
import SecurityObjectService from "../../services/SecurityObjectService";
import { Modal } from "@/components";

export default {
  components: {
    Modal,
  },
  data() {
    return {
      isDisabled: false,
      tableData: [],

      deleteKeyId: null,
      deleteKeyName: null,
      deleteKeyModal:false,
      deletedCheckBox1: false,
      deletedCheckBox2: false,

      //JWS
      jwsModal: false,
      payload: "",
      certificate: "",
      jwsToken:"",

      //Sorting
      currentSort: "created_at",
      currentSortOrder: "asc",
    };
  },
  methods: {
      listSoKeys() {
        this.isDisabled = true;
        SecurityObjectService.list()
          .then((response) => {
            this.isDisabled = false;
            this.tableData = response.data.data;
          })
          .catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.data);
          });
      },
      deleteKey() {
        let data = { keyID: this.deleteKeyId }
        SecurityObjectService.deleteKey(data)
          .then((response) => {
            this.listSoKeys();
            this.deleteKeyModal = false;
          })
          .catch((e) => {
            this.$toast.error(e.response.data.data);
          });
      },
      openDeleteKeyModal(key) {
        this.deleteKeyId = key.kid;
        this.deleteKeyName = key.name
        this.deleteKeyModal = true;
      },
      openJWSModal(key) {
        this.jwsToken = "";
        this.deleteKeyId = key.kid;
        this.deleteKeyName = key.name
        this.jwsModal = true;
      },
      async generateJWS() {
        if (!this.payload) {
          this.$toast.error("Payload is mandatory.");
          return;
        }
        this.isDisabled = true;
        await this.keyAttestation(this.deleteKeyId).then((response) => {
          this.certificate = response.data.data.attestation_statement.statement
        }).catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.data);
            return;
        });
        if (!this.certificate) {
          this.$toast.error("Unable to fetch certificate details.");
          return; 
        }
        let jwsData = {
          payload: this.payload,
          key: this.deleteKeyName,
          cert: this.certificate
        }
        SecurityObjectService.generateJWS(jwsData)
          .then((response) => {
            this.isDisabled = false;
            this.jwsToken = response.data.data.jws;
          }).catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.data);
          });
      },
      keyAttestation : async(keyID) => {
        let data = {
          "key": {
            "kid": keyID
          }
        }
        return await SecurityObjectService.keyAttestation(data);
      },
  },
  beforeMount() {
    this.listSoKeys();
  },
};
</script>
<style>
.md-table-head {
    background-color: #ebedee !important;
    padding: 12px 8px;
    position: relative;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    text-align: left;
}
.md-table[table-header-color=green] .md-table-head .md-table-head-label {
    color: #376092 !important;
}
</style>
