<template>
    <div>
        <div style="float: right">
            <md-button class="md-success" :disabled="isDisabled" @click="createSecurityObject()">Generate<span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span></md-button>
        </div>
        <md-card>
            <md-card-content>
                <form ref="webinarForm">
                    <div class="md-layout">
                        <div class="md-layout-item md-size-50">
                            <md-field>
                                <label>Name</label>
                                <md-input type="text" v-model="name"></md-input>
                            </md-field>
                        </div>
                        <div class="md-layout-item md-size-50">
                            <md-field>
                                <label>Description</label>
                                <md-input type="text" v-model="description"></md-input>
                            </md-field>
                        </div>
                    </div>
                    <div class="md-layout">
                        <div class="md-layout-item md-size-100 mt-2" :style="{ 'display':'ruby'}">
                            <h5><b>Choose a type</b></h5>
                            <md-radio v-model="keyType" value="AES"><p :style="{ color: 'black' }">AES</p></md-radio>
                            <md-radio v-model="keyType" value="RSA"><p :style="{ color: 'black' }">RSA</p></md-radio>
                            <md-radio v-model="keyType" value="EC"><p :style="{ color: 'black' }">EC</p></md-radio>
                            <md-radio v-model="keyType" value="DES3" disabled><p :style="{ color: 'black' }">DES3</p></md-radio>
                            <md-radio v-model="keyType" value="HMAC" disabled><p :style="{ color: 'black' }">HMAC</p></md-radio>
                            <md-radio v-model="keyType" value="DSA" disabled><p :style="{ color: 'black' }">DSA</p></md-radio>
                            <md-radio v-model="keyType" value="DES" disabled><p :style="{ color: 'black' }">DES</p></md-radio>
                            <md-radio v-model="keyType" value="Tokenization" disabled><p :style="{ color: 'black' }">Tokenization</p></md-radio>
                            <md-radio v-model="keyType" value="ARIA" disabled><p :style="{ color: 'black' }">ARIA</p></md-radio>
                            <md-radio v-model="keyType" value="EC-KCDSA" disabled><p :style="{ color: 'black' }">EC-KCDSA</p></md-radio>
                            <md-radio v-model="keyType" value="KCDSA" disabled><p :style="{ color: 'black' }">KCDSA</p></md-radio>
                            <md-radio v-model="keyType" value="SEED" disabled><p :style="{ color: 'black' }">SEED</p></md-radio>
                            <md-radio v-model="keyType" value="BLS" disabled><p :style="{ color: 'black' }">BLS</p></md-radio>
                        </div>
                    </div>
                    <div class="md-layout" v-if="keyType == 'AES'">
                        <div class="md-layout-item md-size-50">
                            <md-field>
                                <label>Key Size</label>
                                <md-select v-model="keySize">
                                    <md-option value="125">125 bits</md-option>
                                    <md-option value="192">192 bits</md-option>
                                    <md-option value="256">256 bits</md-option>
                                </md-select>
                            </md-field>
                        </div>
                    </div>
                    <div class="md-layout" v-if="keyType == 'RSA'">
                        <div class="md-layout-item md-size-50">
                            <md-field>
                                <label>Key Size</label>
                                <md-input type="text" v-model="keySize"></md-input>
                            </md-field>
                            <small>For RSA type choose size from 1024 to 8192.</small>
                        </div>
                        <div class="md-layout-item md-size-50">
                            <md-field>
                                <label>Exponent</label>
                                <md-select v-model="exponent">
                                    <md-option value="65537">65537</md-option>
                                    <md-option value="3">3</md-option>
                                </md-select>
                            </md-field>
                        </div>
                    </div>
                    <div class="md-layout" v-if="keyType == 'EC'">
                        <div class="md-layout-item md-size-50">
                            <md-field>
                                <label>Curve</label>
                                <md-select v-model="curve">
                                    <md-option value="SecP192K1">SecP192K1</md-option>
                                    <md-option value="SecP224K1">SecP224K1</md-option>
                                    <md-option value="SecP256K1">SecP256K1</md-option>
                                    <md-option value="NistP192">NistP192</md-option>
                                    <md-option value="NistP224">NistP224</md-option>
                                    <md-option value="NistP256">NistP256</md-option>
                                    <md-option value="NistP384">NistP384</md-option>
                                    <md-option value="NistP521">NistP521</md-option>
                                    <md-option value="X25519">X25519</md-option>
                                    <md-option value="Ed25519">Ed25519</md-option>
                                </md-select>
                            </md-field>
                        </div>
                    </div>
                    <div class="md-layout">
                        <div class="md-layout-item md-size-100 mt-2" :style="{ 'display':'ruby'}">
                            <h5><b>Key operations permitted</b></h5>
                            <md-checkbox v-model="keyPermitted" :disabled="keyType == 'EC'" value="ENCRYPT" style="{ color: 'black' }">Encrypt</md-checkbox>
                            <md-checkbox v-model="keyPermitted" :disabled="keyType == 'EC'" value="DECRYPT">Decrypt</md-checkbox>
                            <md-checkbox v-model="keyPermitted" :disabled="keyType == 'EC'" value="WRAPKEY">WrapKey</md-checkbox>
                            <md-checkbox v-model="keyPermitted" :disabled="keyType == 'EC'" value="UNWRAPKEY">UnwrapKey</md-checkbox>
                            <md-checkbox v-model="keyPermitted" :disabled="keyType == 'RSA' || keyType == 'EC'" value="DERIVEKEY">DeriveKey</md-checkbox>
                            <md-checkbox v-model="keyPermitted" :disabled="keyType == 'RSA' || keyType == 'EC'" value="MACGENERATE">MacGenerate</md-checkbox>
                            <md-checkbox v-model="keyPermitted" :disabled="keyType == 'RSA' || keyType == 'EC'" value="MACVERIFY">MacVerify</md-checkbox>
                            <md-checkbox v-model="keyPermitted" value="APPMANAGEABLE">App Manageable</md-checkbox>
                            <md-checkbox v-model="keyPermitted" :disabled="keyType == 'AES'" value="SIGN">Sign</md-checkbox>
                            <md-checkbox v-model="keyPermitted" :disabled="keyType == 'AES'" value="VERIFY">Verify</md-checkbox>
                            <md-checkbox v-model="keyPermitted" :disabled="keyType == 'AES' || keyType == 'RSA'" value="AGREEKEY">AgreeKey</md-checkbox>
                            <md-checkbox v-model="keyPermitted" :disabled="keyType == 'AES' || keyType == 'RSA' || keyType == 'EC'" value="Transform">Transform</md-checkbox>
                            <md-checkbox v-model="keyPermitted" value="EXPORT">Export</md-checkbox>
                        </div>
                    </div>
                </form>
            </md-card-content>
        </md-card>
    </div>
</template>
<script>
import SecurityObjectService from "../../services/SecurityObjectService";
export default {
    components: {
    },
    data() {
        return {
            isDisabled: false,
            name: "",
            description: "",
            keyType:"",
            keySize: "256",
            exponent:"65537",
            curve:"SecP192K1",
            keyPermitted:[],
        }
    },
    methods: {
        createSecurityObject() {
            this.isDisabled =true;
            let data = { name: this.name, description: this.description, obj_type: this.keyType, key_ops: this.keyPermitted }
            if (this.keyType == 'EC') {
                data.elliptic_curve= this.curve;
            }
            if (this.keyType == 'RSA') {
                data.public_exponent= parseInt(this.exponent);
                data.key_size = parseInt(this.keySize);
            }
            if (this.keyType == 'AES') {
                data.key_size = parseInt(this.keySize);
            }
            
            SecurityObjectService.create(data)
            .then((response) => {
                this.isDisabled =false;
                this.$router.push("/sobjects");
            })
            .catch((e) => {
                this.isDisabled =false;
                this.$toast.error(e.response.data.data);
            });
        }
    },
    watch: {
        keyType() {
            if (this.keyType == 'RSA') {
                this.keySize = "2048";
                this.keyPermitted = ['APPMANAGEABLE', 'SIGN', 'VERIFY'];
            } else if (this.keyType == 'AES') {
                this.keySize = "256";
                this.keyPermitted = ['ENCRYPT', 'DECRYPT', 'WRAPKEY', 'UNWRAPKEY', 'DERIVEKEY', 'MACGENERATE', 'MACVERIFY','APPMANAGEABLE'];
            } else {
                this.keyPermitted = ['APPMANAGEABLE', 'SIGN', 'VERIFY', 'AGREEKEY'];
            }
        }
    }
}
</script>
<style>
.md-radio-label p {
    margin: 0;
}
.md-checkbox .md-checkbox-label {
    color: unset;
}
</style>